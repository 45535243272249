import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../../components/hero/hero-chevron";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import ContactInfo from "../../../components/contact-info/contact-info";
import DebitCardsOpenAccount from "../../../components/sign-up/debit-cards-open-account";
import ServiceStatesOpenAccountSection from "../../../components/service-states/service-states-open-account-section";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import ProductComparisonTable from "../../../components/product-comparison-table/product-comparison-table";
import FeaturesSection from "../../../components/rewards-checking/features-section";
import StartSavingsSection from "../../../components/start-savings-section/start-savings-section";

const RewardsChecking = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const title = "Rewards Checking Accounts - Compare & Open Online",
    description =
      "Learn about the different types of rewards checking accounts at WaFd Bank including our Premium Rewards Checking Account that includes travel discounts.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/checking-account/rewards-checking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-rewards-checking-040424.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "rewards-checking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Woman sitting on a car with arms raised in celebration, looking at beautiful mountain scenery.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Get the Rewards You Deserve"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn",
            text: "Open an Account",
            url: "/open-an-account",
            class: "btn-white",
            containerClass: "mb-3"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Rewards and Premium Rewards Checking"
    }
  ];

  const debitCardData = {
    sectionId: "rewards-checking-debit-cards-section",
    titleText: "Let's Open Your Rewards Checking Account Today",
    depositText: "Only $25 initial deposit to open."
  };

  const videoData = {
    vimeoId: "984569436",
    videoTitle: "Rewards Checking Account Perks at WaFd Bank",
    class: "m-auto iframe w-100 border-radius-12"
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Rewards & Premium Rewards Checking</h1>
        <div className="row">
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <VimeoVideoFrame {...videoData} />
          </div>
          <div className="col-12 col-md-6">
            <h4 className="mb-0">
              Upgrade your account with Rewards or Premium Rewards Checking from WaFd Bank. Enjoy a secure bank account
              that offers numerous benefits on the things in life that matter most, like gas rewards, identity theft
              protection and resolution, plus shopping, travel, and leisure discounts. Get the account that does more
              for you!
            </h4>
          </div>
        </div>
      </section>
      <ProductComparisonTable />
      <DebitCardsOpenAccount {...debitCardData} />
      <FeaturesSection />
      <StartSavingsSection isGray={true} />
      <ServiceStatesOpenAccountSection containerClass="bg-white" />
      <ContactInfo />
      <section className="container">
        <p className="text-gray-60 text-sm" id="interest-rewards-checking-disclaimer-1">
          Benefits are available to personal checking account owner(s) and their joint account owners subject to the
          terms and conditions for the applicable Benefits. Some Benefits require authentication, registration and/or
          activation. Benefits are not available to a "signer" on the account who is not an account owner or to
          businesses, clubs, trusts, organizations and/or churches and their members, or schools and their
          employees/students. Restrictions may apply. For additional details access your Gas Savings benefit through
          your WaFd Online banking after May 20, 2024.
        </p>
        <p className="text-gray-60 text-sm" id="interest-rewards-checking-disclaimer-2">
          Special Program Notes: The descriptions herein are summaries only and do not include all terms, conditions and
          exclusions of the Benefits described. Please refer to the actual Guide to Benefit and/or insurance documents
          for complete details of coverage and exclusions. Coverage is provided through the company named in the Guide
          to Benefit or on the certificate of insurance. Guide to Benefit and insurance documents can be found through
          your WaFd Online banking or WaFd Bank mobile app after May 20, 2024. If you wish to name a beneficiary for
          your Accidental Death and Dismemberment Insurance coverage, please complete a beneficiary form. If there is no
          designated beneficiary on file, benefits will be paid according to the Master Policy. As an eligible account
          owner, you are enrolled as a member of the Econocheck Association, of which your financial institution is a
          sponsor. For more information, please call{" "}
          <a href="tel:866-210-0361" id="benefits-customer-service-tel-link">
            866-210-0361
          </a>
          . Insurance Products are not insured by the FDIC or any Federal Government Agency; not a deposit of or
          guaranteed by the bank or any bank affiliate. Available for the account holder and their spouse/domestic
          partner and up to six (6) dependent children age 2 and older. This is not insurance. Credit Score is a
          VantageScore 3.0 based on single bureau data. Third parties may use a different VantageScore or a different
          type of credit score to assess your creditworthiness. Once you have activated credit file monitoring, you may
          request your credit score. Once you have done so, you will have access to your score on a monthly basis.
          Health Discount Savings is NOT insurance. Registration/activation required. Shopping Rewards require
          registration/activation; available via mobile and web only. Travel and Leisure Discounts are available via
          mobile and web only.
        </p>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default RewardsChecking;
